import { EntityTypeValue, PossibleValueForSelect } from '@/src/modules/common/models/common.models';
import { ActivityScope } from '@/src/modules/srm/models/activity.models';
import { MemberInviteEntity, MemberRole, Titulus } from '@/src/modules/srm/models/member.models';
import { TeamMemberRolesArray } from '@/src/modules/teams/team.member.consts';
import {
	TeamMemberInviteEntity,
	TeamMemberRole,
	TeamMemberScope,
} from '@/src/modules/teams/team.member.models';

export const MemberEntityLocalizedIds = {
	format: 'domain.proposal.participation.format',
};

export type AddMemberContentBlockMode = 'ACTIVITY' | TeamMemberScope;

export const MemberNewDefaultValue = (
	mode: AddMemberContentBlockMode,
	scope: ActivityScope,
): MemberInviteEntity | TeamMemberInviteEntity => {
	const invitee = {
		email: '',
		firstName: '',
	};

	if (mode === 'ACTIVITY') {
		return {
			format: 'ONLINE_REMOTE',
			role: 'EXPERT',
			invitee,
			scope: scope === 'PARTNER' ? 'PARTNER' : 'REGULAR',
		} as MemberInviteEntity;
	} else {
		return {
			roles: ['PROGRAM_COMMITTEE'],
			invitee,
			scope: mode,
		} as TeamMemberInviteEntity;
	}
};

export const MemberEntityFormatValues = (scope: ActivityScope): EntityTypeValue<string>[] => {
	const isPartnerScope = scope === 'PARTNER';
	const isMeetupScope = scope === 'MEETUP';

	if (isMeetupScope) {
		return [
			{
				key: 'ONLINE_REMOTE',
				displayId: 'domain.proposal.participation.format.ONLINE_REMOTE.meetup',
			},
			{
				key: 'ONLINE_STUDIO',
				displayId: 'domain.proposal.participation.format.ONLINE_STUDIO.meetup',
			},
			{
				key: 'UNKNOWN',
				displayId: 'domain.proposal.participation.format.UNKNOWN',
				disabled: true,
			},
		];
	}

	if (isPartnerScope) {
		return [
			// {
			//     key: "OFFLINE",
			//     displayId: "domain.proposal.participation.format.OFFLINE"
			// },
			{
				key: 'ONLINE_REMOTE',
				displayId: 'domain.proposal.participation.format.ONLINE_REMOTE.partner',
			},
			{
				key: 'ONLINE_STUDIO',
				displayId: 'domain.proposal.participation.format.ONLINE_STUDIO.partner',
			},
			{
				key: 'UNKNOWN',
				displayId: 'domain.proposal.participation.format.UNKNOWN',
			},
		];
	}

	return [
		{
			key: 'ONLINE_REMOTE',
			displayId: 'domain.proposal.participation.format.ONLINE_REMOTE',
		},
		{
			key: 'ONLINE_STUDIO',
			displayId: 'domain.proposal.participation.format.ONLINE_STUDIO',
		},
		{
			key: 'OFFLINE',
			displayId: 'domain.proposal.participation.format.OFFLINE',
		},
		{
			key: 'UNKNOWN',
			displayId: 'domain.proposal.participation.format.UNKNOWN',
		},
	];
};

export const GetMemberEntityRoleValueDisplayValue = (
	role?: TeamMemberRole | MemberRole | string,
	declination: '1d' | '2d' = '1d',
): string => `domain.member.roles.${declination}.${role}`;

export const GetMemberEntityRoleValueDescriptionDisplayValue = (
	role?: TeamMemberRole | MemberRole | string,
): string => `domain.member.roles.description.${role}`;

export const TitulusPossibleValuesForSelect = (): PossibleValueForSelect[] =>
	TitulusPossibleValues.map((name) => ({
		name: name as string,
		code: name === '-' ? undefined : name,
	}));

export const TitulusPossibleValues: Titulus[] = [
	'Java Champion',
	'Microsoft Most Valuable Professional',
	'Google Developer Expert',
	'MVP Reconnect',
	'-',
];

export const MemberRolesArray = ['SPEAKER', 'EXPERT', 'HOST'] as const;

export const RolesPossibleValuesForSelect = (
	localeAs: (id: string) => string,
	mode: AddMemberContentBlockMode,
): PossibleValueForSelect[] =>
	(mode === 'ACTIVITY' ? MemberRolesArray : TeamMemberRolesArray).map((code) => ({
		name: localeAs(GetMemberEntityRoleValueDisplayValue(code)),
		code,
	}));

export const BiographyLocalizedIds = {
	content: {
		data: {
			firstName: 'page.proposal.content.tabs.speakers.infoBlock.firstname',
			lastName: 'page.proposal.content.tabs.speakers.infoBlock.lastname',
			company: 'page.proposal.content.tabs.speakers.infoBlock.company',
			description: 'page.proposal.content.tabs.speakers.infoBlock.bio',
			titulus: 'page.proposal.content.tabs.speakers.infoBlock.titul',
			twitter: 'page.proposal.content.tabs.speakers.infoBlock.twitter',
			position: 'page.proposal.content.tabs.speakers.infoBlock.position',
			email: 'page.proposal.content.tabs.speakers.infoBlock.email',
			phone: 'page.proposal.content.tabs.speakers.infoBlock.phone',
			role: 'page.proposal.content.tabs.speakers.infoBlock.role',
			isPartner: 'page.proposal.content.tabs.speakers.infoBlock.isPartner',
			isMeetup: 'page.proposal.content.tabs.speakers.infoBlock.isMeetup',
		},
	},
};

export const MemberLocalizedIds = {
	biography: BiographyLocalizedIds,
	consent: {
		publicationConsent: 'page.proposal.content.tabs.speakers.infoBlock.publicationConsent',
	},
};
