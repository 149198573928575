'use client';

import { useEffect, useMemo, useState } from 'react';

import { useGET, UseGETReloadFunction } from '@/src/modules/common/hooks/useGET';
import { usePATCH } from '@/src/modules/common/hooks/usePATCH';
import { usePOST } from '@/src/modules/common/hooks/usePOST';
import { ApiOnceResponse } from '@/src/modules/common/models/common.models';
import { RightsError } from '@/src/modules/common/utils/errors/RightsError';
import { eventsActions } from '@/src/modules/events/actions/events.actions';
import { useProject } from '@/src/modules/events/hooks/useProject';
import { useSeason } from '@/src/modules/events/hooks/useSeason';
import { PatchEventRequest } from '@/src/modules/events/models/events.create.models';
import { EventEntity } from '@/src/modules/events/models/events.models';
import { projectEventsRoutes } from '@/src/modules/events/routes/events.api.routes';

export type UseEvent = {
	event?: EventEntity | null;
	isLoading: boolean;
	error?: Error | unknown;
	reload: UseGETReloadFunction;
	archiveEvent: () => Promise<EventEntity | undefined | null>;
	patchEvent: (data: PatchEventRequest) => Promise<EventEntity | undefined | null>;
};

export const useEvent = (workspaceId?: string, eventId?: number, canGet = true): UseEvent => {
	const [endpoints, setEndpoints] = useState<
		{ event: string; eventArchive: string; eventPatch: string } | undefined
	>();

	const [event, setEvent] = useState<EventEntity | null | undefined>();
	const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(true);
	const [error, setError] = useState<unknown>();

	const {
		data,
		isLoading,
		reload,
		error: eventLoadingError,
	} = useGET<ApiOnceResponse<EventEntity>, unknown>({
		endpoint: endpoints?.event,
		canGet: endpoints?.event !== undefined && canGet,
	});

	const { season, isLoading: seasonIsLoading } = useSeason(data?.data?.seasonId);
	const { project, isLoading: projectIsLoading } = useProject(data?.data?.projectId);

	const archiveCommitteeEventPost = usePOST<unknown, { data: EventEntity }>({
		endpoint: endpoints?.eventArchive,
	});

	const patchEventPatch = usePATCH<unknown, any>({
		endpoint: endpoints?.eventPatch,
	});

	useEffect(() => {
		if (eventId && workspaceId) {
			setEndpoints({
				event: projectEventsRoutes.eventApiPath(eventId),
				eventArchive: projectEventsRoutes.eventArchiveApiPath(eventId),
				eventPatch: projectEventsRoutes.eventApiPath(eventId),
			});
		}
	}, [eventId, workspaceId]);

	useEffect(() => {
		if (data?.data) {
			if (data.data.workspaceId !== workspaceId) {
				setEvent(null);
				setError(new RightsError('403 Forbidden'));
				return;
			}

			setEvent(
				eventsActions.addRightsToEvent(
					eventsActions.transformEvent(data?.data, season, project),
				),
			);
		}
	}, [data?.data, season, project]);

	useEffect(() => {
		setIsLoadingEvent(isLoading && seasonIsLoading && projectIsLoading);
	}, [isLoading, seasonIsLoading, projectIsLoading]);

	useEffect(() => {
		if (eventLoadingError) {
			setError(eventLoadingError);
		}
	}, [eventLoadingError]);

	const archiveEvent = async () => {
		setIsLoadingEvent(true);

		const eventResponse = (await archiveCommitteeEventPost())?.data;

		setEvent({ ...eventResponse });

		setIsLoadingEvent(false);

		return eventResponse;
	};

	const patchEvent = async (data: PatchEventRequest) => {
		setIsLoadingEvent(true);

		const eventResponse = (await patchEventPatch(data))?.data;

		setEvent({ ...eventResponse });

		setIsLoadingEvent(false);

		return eventResponse;
	};

	return useMemo(
		() => ({
			event,
			isLoading: isLoadingEvent,
			error,
			reload,
			archiveEvent,
			patchEvent,
		}),
		[event, isLoadingEvent, reload, archiveEvent, patchEvent, error],
	);
};
