import { FilterData } from '@/src/modules/common/hooks/useFilterData';
import {
	ActivityEntityScopeValuePrefix,
	ActivityEntityTypeValuePrefix,
} from '@/src/modules/srm/consts/const';

export const COMMON_FILTER_TYPE_KEY = 'page_publications_filter_type';
export const COMMON_FILTER_SCOPE_KEY = 'page_publications_filter_scope';
export const COMMON_FILTER_TEXT_KEY = 'page_publications_search';

export const COMMON_FILTER_TYPE_KEY_FILTER = (f: FilterData) => f.key === COMMON_FILTER_TYPE_KEY;
export const COMMON_FILTER_SCOPE_KEY_FILTER = (f: FilterData) => f.key === COMMON_FILTER_SCOPE_KEY;
export const COMMON_FILTER_TEXT_KEY_FILTER = (f: FilterData) => f.key === COMMON_FILTER_TEXT_KEY;

export const COMMON_FILTER_TEXT_KEY_DEFAULT = (label: string): FilterData => ({
	key: COMMON_FILTER_TEXT_KEY,
	label,
	data: '',
	type: 'text',
	order: -1,
});

export const COMMON_FILTER_TYPE_KEY_DEFAULT: FilterData = {
	key: COMMON_FILTER_TYPE_KEY,
	label: ActivityEntityTypeValuePrefix,
	data: [],
	type: 'text',
	order: 3,
};

export const COMMON_FILTER_SCOPE_KEY_DEFAULT: FilterData = {
	key: COMMON_FILTER_SCOPE_KEY,
	label: ActivityEntityScopeValuePrefix,
	data: [],
	type: 'text',
	order: 2,
};
