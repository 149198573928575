import { commonActions } from '@/src/modules/common/actions/common.actions';
import { rightsActions } from '@/src/modules/common/actions/rights.actions';
import { EntityActions, Form } from '@/src/modules/common/models/common.models';
import { removeUndefined } from '@/src/modules/common/utils';
import { eventsActions } from '@/src/modules/events/actions/events.actions';
import { EventEntity } from '@/src/modules/events/models/events.models';
import { proposalActions } from '@/src/modules/srm/actions/proposal.actions';
import { MemberEntityFormatValues } from '@/src/modules/srm/constants/member.consts';
import { ActivityScope } from '@/src/modules/srm/models/activity.models';
import { LocationModel } from '@/src/modules/srm/models/location.models';
import {
	ActivityMemberEntity,
	BiographyEntity,
	ExtendedBiographyEntity,
	MemberAbstractEntity,
	MemberConsent,
} from '@/src/modules/srm/models/member.models';
import { ProposalEntity } from '@/src/modules/srm/models/proposal.models';
import { ContentStatus } from '@/src/modules/srm/models/squidex.models';
import { TravelModel } from '@/src/modules/srm/models/travel.models';

export const memberActions = {
	addRightsToMember: (data: ActivityMemberEntity & EntityActions) => ({
		...data,
		rights: {
			...commonActions.entityActions.getRightsFromEntity(data),
			canDelete: rightsActions.canDeleteNestedResource(data, 'member'),
			canListBiography: rightsActions.canListNestedResource(data, 'biography'),
			canUploadPhoto: rightsActions.canNestedResource(data, 'member', 'upload-photo'),
			// TODO mv to ???
			canSendNotification: false,
		},
	}),
	sortBiographiesByEvent(a: ExtendedBiographyEntity, b: ExtendedBiographyEntity) {
		const aEvents = a.referencedBy
			?.filter((r) => r.type === 'event')
			.map((r) => r.entity as EventEntity)
			.sort(eventsActions.comparatorEventByDate);
		const bEvents = b.referencedBy
			?.filter((r) => r.type === 'event')
			.map((r) => r.entity as EventEntity)
			.sort(eventsActions.comparatorEventByDate);

		if (!aEvents || !bEvents) {
			return -1;
		}

		return eventsActions.comparatorEventByDate(aEvents[0], bEvents[0]);
	},
	isMemberPublicationConsentAccepted: (member?: ActivityMemberEntity) => {
		if (!member?.eventProfile?.consent) {
			return undefined;
		}

		return memberActions.isMemberConsentAccepted(member.eventProfile.consent);
	},
	isMemberConsentAccepted: (consent?: MemberConsent) => {
		if (!consent) {
			return false;
		}

		return consent.content_publishing?.accepted;
	},
	getPublicationConsentForm: (consent?: MemberConsent): Form | undefined => {
		if (!consent) {
			return undefined;
		}

		return consent.content_publishing;
	},
	getBiographyContentPublishedStatus: (
		proposal?: ProposalEntity,
		member?: MemberAbstractEntity,
	): ContentStatus => {
		const productionStage = !proposal
			? true
			: proposalActions.isProposalProductionStage(proposal);
		const activityDraft = !proposal?.activity
			? false
			: commonActions.contentActions.isDraft(proposal?.activity?.content?.status);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return !productionStage
			? 'DRAFT'
			: activityDraft
				? 'DRAFT'
				: member?.eventProfile?.biography?.content?.status;
	},
	isSecondaryRole: (member?: ActivityMemberEntity): boolean => member?.role === 'EXPERT',
	isTravelPossible: (member?: ActivityMemberEntity): boolean =>
		member?.format === undefined
			? false
			: member?.format === 'ONLINE_STUDIO' || member?.format === 'OFFLINE',
	isLocationPossible: (member?: ActivityMemberEntity): boolean =>
		member?.format === undefined ? false : member?.format === 'ONLINE_REMOTE',
	isGiftPossible: (member: ActivityMemberEntity): boolean =>
		member?.format === undefined ? false : true,
	// REMOVE by Oksana 20.04.2022
	// participation?.format === "ONLINE_REMOTE" || participation?.format === "ONLINE_STUDIO",
	getFormatLocalizeId: (
		member?: ActivityMemberEntity,
		scope?: ActivityScope,
	): string | undefined =>
		member?.format === undefined
			? // eslint-disable-next-line @typescript-eslint/ban-ts-comment,no-mixed-spaces-and-tabs
				undefined // @ts-ignore
			: MemberEntityFormatValues(scope).find((f) => f.key === member.format)?.displayId,
	getMeFromMembers: (members: ActivityMemberEntity[]) => members.find((p) => p.isMe),
	getOtherMembers: (member: ActivityMemberEntity[]) => member.filter((p) => !p.isMe),
	extractBiographyImages: (biography?: BiographyEntity) => {
		const photoIds = biography?.content?.data?.photo?.iv;

		if (!photoIds) {
			return undefined;
		}

		if (photoIds.length === 0) {
			return [];
		}

		const assets = biography?.content?.assets?.filter((a) => photoIds.includes(a.id ?? ''));

		return assets?.map((a) => a.links?.content);
	},
	extractContentData: (biography?: BiographyEntity) => biography?.content?.data,
	extractTwitterData: (biography?: BiographyEntity) =>
		commonActions.twitterActions.getFieldValueWithoutSymbols(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			biography?.content?.data?.contacts?.iv?.filter((c) => c.type === 'twitter')[0]?.value,
			'@',
		),
	editAction: {
		removeMemberNotEditableFields: (data: ActivityMemberEntity): ActivityMemberEntity =>
			removeUndefined({
				...data,
				id: undefined,
				actions: undefined,
				role: undefined,
				status: undefined,
				isMe: undefined,
				contributor: undefined,
				biography: undefined,
			} as ActivityMemberEntity),
		removeNotEditableFields: (data: LocationModel | TravelModel): LocationModel | TravelModel =>
			removeUndefined({
				...data,
				id: undefined,
				contributorId: undefined,
				actions: undefined,
			}),
		removeBiographyNotEditableFields: (
			data: BiographyEntity,
			withPhoto?: boolean,
		): BiographyEntity =>
			removeUndefined({
				...data,
				id: undefined,
				actions: undefined,
				contributorId: undefined,
				participationId: undefined,
				version: undefined,
				status: undefined,
				referencedBy: undefined,
				content: {
					...data.content,
					id: undefined,
					created: undefined,
					status: undefined,
					schemaId: undefined,
					schemaName: undefined,
					lastModified: undefined,
					assets: undefined,
					data: {
						...data.content?.data,
						id: undefined,
						ownerId: undefined,
						photo: withPhoto ? data.content?.data?.photo : undefined,
					},
					schema: undefined,
					version: undefined,
				},
			}),
	},
	sortMembersByRole(a: ActivityMemberEntity, b: ActivityMemberEntity) {
		if (a.role === b.role) {
			return (a.id ?? 0) - (b.id ?? 0);
		}

		if (a.role === 'SPEAKER') {
			return -1;
		}

		if (a.role === 'EXPERT' && b.role !== 'SPEAKER') {
			return -1;
		}

		return 1;
	},
	// isEditingEnabled: (
	// 	participation?: ParticipationEntity,
	// 	proposal?: ProposalEntity,
	// 	authStore?: AuthStore,
	// ) => {
	// 	if (!participation || !proposal?.activity || !proposal.event?.id || !authStore) {
	// 		return false;
	// 	}
	//
	// 	const isPartnerScope = activityActions.isPartnerScope(proposal?.activity);
	//
	// 	if (
	// 		isPartnerScope &&
	// 		!(authStore.isGlobalAdmin || authStore.isCoordinator(proposal.event?.id))
	// 	) {
	// 		return false;
	// 	}
	//
	// 	return rightsActions.canEdit(participation);
	// },
	// canEditSomeParticipation(currentParticipations?: ParticipationEntity[]) {
	// 	if (!currentParticipations || currentParticipations?.length === 0) {
	// 		return false;
	// 	}
	//
	// 	return currentParticipations.some(rightsActions.canEdit);
	// },
	// sortCommitteeParticipations(a: CommitteeParticipationEntity, b: CommitteeParticipationEntity) {
	// 	if (a.role === 'COORDINATOR' && b.role !== 'COORDINATOR') {
	// 		return -1;
	// 	}
	//
	// 	if (a.role === 'COORDINATOR' && b.role === 'COORDINATOR') {
	// 		return a.id - b.id;
	// 	}
	//
	// 	if (a.role === b.role) {
	// 		return a.id - b.id;
	// 	}
	//
	// 	return 1;
	// },
	// sortParticipationsByRole(a: ParticipationEntity, b: ParticipationEntity) {
	// 	if (a.role === b.role) {
	// 		return a.id - b.id;
	// 	}
	//
	// 	if (a.role === 'SPEAKER') {
	// 		return -1;
	// 	}
	//
	// 	if (a.role === 'EXPERT' && b.role !== 'SPEAKER') {
	// 		return -1;
	// 	}
	//
	// 	return 1;
	// },
	// getActivitiesIdFromBios(bios: BiographyEntity[]) {
	// 	const ids = bios
	// 		.flatMap((b) => b.referencedBy)
	// 		.filter((r) => r.type === 'activity')
	// 		.map((r) => r.id);
	//
	// 	return [...new Set(ids)];
	// },
	// getEventsIdFromBios(bios: BiographyEntity[]) {
	// 	const ids = bios
	// 		.flatMap((b) => b.referencedBy)
	// 		.filter((r) => r.type === 'event')
	// 		.map((r) => r.id);
	//
	// 	return [...new Set(ids)];
	// },
	// sortBiographiesByEvent(a: ExtendedBiographyEntity, b: ExtendedBiographyEntity) {
	// 	const aEvents = a.referencedBy
	// 		?.filter((r) => r.type === 'event')
	// 		.map((r) => r.entity as CoreApiEventEntity)
	// 		.sort(coreApiAction.comparatorEventByDate);
	// 	const bEvents = b.referencedBy
	// 		?.filter((r) => r.type === 'event')
	// 		.map((r) => r.entity as CoreApiEventEntity)
	// 		.sort(coreApiAction.comparatorEventByDate);
	//
	// 	if (!aEvents || !bEvents) {
	// 		return -1;
	// 	}
	//
	// 	return coreApiAction.comparatorEventByDate(aEvents[0], bEvents[0]);
	// },
	// mergeParticipantBiographiesWithActivities(
	// 	bios: BiographyEntity[],
	// 	activities: ActivityEntity[],
	// ): ExtendedBiographyEntity[] {
	// 	return bios
	// 		.map((b) => {
	// 			return {
	// 				...b,
	// 				referencedBy: b.referencedBy?.map((r) => {
	// 					if (r?.type === 'activity') {
	// 						return {
	// 							...r,
	// 							entity: activities.find((a) => a.id === r.id),
	// 						};
	// 					}
	// 					return r;
	// 				}),
	// 			};
	// 		})
	// 		.sort(memberActions.sortBiographiesByEvent);
	// },
	// mergeParticipantBiographiesWithEvents(
	// 	bios: BiographyEntity[],
	// 	events: CoreApiEventEntity[],
	// ): ExtendedBiographyEntity[] {
	// 	return bios
	// 		.map((b) => {
	// 			return {
	// 				...b,
	// 				referencedBy: b.referencedBy?.map((r) => {
	// 					if (r?.type === 'event') {
	// 						return {
	// 							...r,
	// 							entity: events.find((a) => a.id === r.id),
	// 						};
	// 					}
	// 					return r;
	// 				}),
	// 			} as ExtendedBiographyEntity;
	// 		})
	// 		.sort(memberActions.sortBiographiesByEvent);
	// },
	// getEventsFromBio(biography: ExtendedBiographyEntity) {
	// 	return biography?.referencedBy
	// 		?.filter((r) => r.type === 'event')
	// 		.map((r) => r.entity as CoreApiEventEntity)
	// 		.sort(coreApiAction.comparatorEventByDate);
	// },
};
