import { LocaleMessageType } from '@/src/modules/common/models/common.models';
import { activityActions } from '@/src/modules/srm/actions/activity.actions';
import {
	ProposalAdditionalActionsAvailableStages,
	ProposalAdditionalActionsNotAvailableStatuses,
	ProposalClosedStatuses,
	ProposalProductionStages,
} from '@/src/modules/srm/constants/proposal.consts';
import { ProposalEntity } from '@/src/modules/srm/models/proposal.models';
import { memberActions } from '@/src/modules/srm/actions/member.actions';

export const proposalActions = {
	addRightsToProposal: (data: ProposalEntity): ProposalEntity => ({
		...data,
		activity: activityActions.addRightsToActivity(data?.activity),
		members: data?.members?.map(memberActions.addRightsToMember),
	}),
	isClosedProposal: (proposal?: ProposalEntity) =>
		!!(proposal?.workflow?.status
			? ProposalClosedStatuses.includes(proposal.workflow.status)
			: undefined),
	isNonClosedProposal: (proposal?: ProposalEntity) =>
		proposal?.workflow?.status
			? !ProposalClosedStatuses.includes(proposal.workflow.status)
			: true,
	isProposalProductionStage: (proposal?: ProposalEntity) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		ProposalProductionStages.includes(proposal?.workflow?.stage) ||
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		ProposalProductionStages.includes(proposal?.stage),
	isAdditionalActionsAvailable: (proposal?: ProposalEntity) =>
		(proposal?.workflow?.status
			? !ProposalAdditionalActionsNotAvailableStatuses.includes(proposal.workflow.status)
			: true) &&
		!!(proposal?.workflow?.stage
			? ProposalAdditionalActionsAvailableStages.includes(proposal.workflow.stage)
			: undefined),
	getDescriptionForStatus: (
		proposal: ProposalEntity | undefined,
		localeAs: (id: LocaleMessageType) => string,
	): { title: string; description: string; severity: string } => {
		const severity: string = proposalActions.isNonClosedProposal(proposal)
			? 'success'
			: proposalActions.isClosedProposal(proposal)
				? 'info'
				: 'warning';

		if (proposal?.workflow?.status) {
			return {
				title: localeAs(
					`domain.proposal.workflow.status.${proposal?.workflow?.status}` as any,
				),
				description: localeAs(
					`domain.proposal.workflow.status.${proposal?.workflow?.status}.description` as any,
				),
				severity,
			};
		}
		return { title: '', description: '', severity };
	},
};
