import classNames from 'classnames';

import styles from './sidebar.module.scss';

import { routes, routesParts, urlWithId } from '@/src/modules/common/routes/common.routes';
import { Routes } from '@/src/modules/common/routes/common.routes.types';
import { Link } from '@/src/modules/common/utils';
import { Locale } from '@/src/modules/i18n';
import { useTranslations } from 'next-intl';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';

import ChevronDownIcon from '../../../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../../../icons/ChevronUpIcon';

export const EVENTS_PARENT_ITEM_ID = 'events';
export const DASHBOARD_PARENT_ITEM_ID = 'dashboard';
export const USERS_PARENT_ITEM_ID = 'users';
export const PROJECTS_PARENT_ITEM_ID = 'projects';
export const VENUES_PARENT_ITEM_ID = 'venues';
export const TEAM_PARENT_ITEM_ID = 'team';

type ExtendedMenuItem = MenuItem & {
	ii?: boolean;
	iii?: boolean;
	iv?: boolean;
	locale?: Locale;
};

const itemRenderer = (item: ExtendedMenuItem, options: MenuItemOptions) => {
	const { active } = options as MenuItemOptions & { active: boolean };

	return (
		<Link
			className={classNames(
				styles.link,
				'px-3 py-2 cursor-pointer',
				item.ii && styles.ii,
				item.iii && styles.iii,
				item.iv && styles.iv,
			)}
			// onClick={options.onClick}
			locale={item.locale}
			href={item.url ?? '#'}
		>
			<span className={`${item.icon}`} />
			<span className={`mx-2`}>{item.label}</span>

			{/*{item.id?.startsWith('season-') && (*/}
			{/*	<Badge severity={'info'} className="" value={item.items?.length ?? 0} />*/}
			{/*)}*/}

			{item.items && item.items.length > 0 && (
				<span
					className={classNames('ml-auto', item.iii && 'opacity-30')}
					style={{ marginBottom: '-3px' }}
				>
					{active ? <ChevronUpIcon /> : <ChevronDownIcon />}
				</span>
			)}

			{/*{item.id === EVENTS_PARENT_ITEM_ID && (!item.items || item.items.length === 0) && (*/}
			{/*	<span className={classNames('ml-auto')}>*/}
			{/*		<i className={'pi pi-spin pi-spinner'} />*/}
			{/*	</span>*/}
			{/*)}*/}
		</Link>
	);
};

export const createDashboardItem = (
	t: ReturnType<typeof useTranslations>,
	workspaceIdOrSlug?: string,
): MenuItem => ({
	id: DASHBOARD_PARENT_ITEM_ID,
	label: t('items.dashboard'),
	icon: 'pi pi-chart-line',
	url: urlWithId(routes[Routes.Dashboard].path, routesParts.WorkspaceIdOrSlug, workspaceIdOrSlug),
	disabled: !workspaceIdOrSlug,
	template: itemRenderer,
});

export const createEventsItem = (
	t: ReturnType<typeof useTranslations>,
	workspaceIdOrSlug?: string,
): MenuItem => ({
	id: EVENTS_PARENT_ITEM_ID,
	label: t('items.events'),
	icon: 'pi pi-check-square',
	url: urlWithId(routes[Routes.WorkspaceEvents].path, routesParts.WorkspaceIdOrSlug, workspaceIdOrSlug),
	template: itemRenderer,
	disabled: !workspaceIdOrSlug,
});

export const createUsersItem = (
	t: ReturnType<typeof useTranslations>,
	disabled: boolean,
	workspaceIdOrSlug?: string,
): MenuItem => ({
	id: USERS_PARENT_ITEM_ID,
	label: t('items.users'),
	icon: 'pi pi-users',
	url: urlWithId(routes[Routes.WorkspaceUsers].path, routesParts.WorkspaceIdOrSlug, workspaceIdOrSlug),
	template: itemRenderer,
	disabled,
});

export const createProjectsItem = (
	t: ReturnType<typeof useTranslations>,
	workspaceIdOrSlug?: string,
): MenuItem => ({
	id: PROJECTS_PARENT_ITEM_ID,
	label: t('items.projects'),
	icon: 'pi pi-palette',
	url: urlWithId(
		routes[Routes.WorkspaceProjects].path,
		routesParts.WorkspaceIdOrSlug,
		workspaceIdOrSlug,
	),
	template: itemRenderer,
	disabled: !workspaceIdOrSlug,
});

export const createVenuesItem = (
	t: ReturnType<typeof useTranslations>,
	workspaceIdOrSlug?: string,
): MenuItem => ({
	id: VENUES_PARENT_ITEM_ID,
	label: t('items.venues'),
	icon: 'pi pi-shop',
	url: urlWithId(routes[Routes.WorkspaceVenues].path, routesParts.WorkspaceIdOrSlug, workspaceIdOrSlug),
	template: itemRenderer,
	disabled: !workspaceIdOrSlug,
});

export const createTeamItem = (
	t: ReturnType<typeof useTranslations>,
	workspaceIdOrSlug?: string,
): MenuItem => ({
	id: TEAM_PARENT_ITEM_ID,
	label: t('items.team'),
	icon: 'pi pi-users',
	url: `${urlWithId(routes[Routes.Team].path, routesParts.WorkspaceIdOrSlug, workspaceIdOrSlug)}`,
	template: itemRenderer,
	disabled: !workspaceIdOrSlug,
});
