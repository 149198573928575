import { TeamMemberScope } from '@/src/modules/teams/team.member.models';

export const TeamMemberScopesArray = ['WORKSPACE', 'PROJECT', 'EVENT'];
export const TeamMemberRolesForWorkspaceArray = [
	'OWNER',
	'MANAGER',
	'PARTNER_COORDINATOR',
	'PROGRAM_COORDINATOR',
	'SUPPORT_LEAD',
	'SUPPORT_JUNIOR',
	'SUPPORT_MODERATOR',
	'BROADCAST_LEAD',
	'BROADCAST_JUNIOR',
	'BROADCAST_MODERATOR',
	'MARKETING_EDITOR',
	'MARKETING_GENERAL',
	'VIEWER_GLOBAL',
	'VIEWER_REPORT',
	'VIEWER_GENERAL',
] as const;

export const TeamMemberRolesForProjectArray = [
	'MANAGER',
	'PARTNER_COORDINATOR',
	'PROGRAM_COORDINATOR',
	'PROGRAM_COMMITTEE',
	'SUPPORT_LEAD',
	'SUPPORT_JUNIOR',
	'SUPPORT_MODERATOR',
	'BROADCAST_LEAD',
	'BROADCAST_JUNIOR',
	'BROADCAST_MODERATOR',
	'MARKETING_EDITOR',
	'MARKETING_GENERAL',
	'VIEWER_GLOBAL',
	'VIEWER_REPORT',
	'VIEWER_GENERAL',
] as const;

export const TeamMemberRolesForEventArray = [
	'SUPPORT_LEAD',
	'SUPPORT_JUNIOR',
	'SUPPORT_MODERATOR',
	'BROADCAST_LEAD',
	'BROADCAST_JUNIOR',
	'BROADCAST_MODERATOR',
	'MARKETING_EDITOR',
	'MARKETING_GENERAL',
	'VIEWER_GLOBAL',
	'VIEWER_REPORT',
	'VIEWER_GENERAL',
] as const;

export const TeamMemberRolesArray = [
	...new Set([
		...TeamMemberRolesForWorkspaceArray,
		...TeamMemberRolesForProjectArray,
		...TeamMemberRolesForEventArray,
	]),
] as string[];

export const TeamMemberRoleScopeMap = new Map<TeamMemberScope, string[]>([
	['WORKSPACE', []],
	['PROJECT', []],
	['EVENT', []],
]);
