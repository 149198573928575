import { workspaceApiPath } from '@/src/modules/workspaces/routes/workspaces.api.routes';

export const projectEventsRoutes = {
	eventsApiPath: `/events`,
	projectsApiPath: `/projects`,
	seasonsApiPath: `/seasons`,
	workspacesEventsApiPath: (workspaceId: string) =>
		`${workspaceApiPath(workspaceId)}${projectEventsRoutes.eventsApiPath}`,
	workspacesProjectsApiPath: (workspaceId: string) =>
		`${workspaceApiPath(workspaceId)}${projectEventsRoutes.projectsApiPath}`,
	projectApiPath: (projectId: string) => `${projectEventsRoutes.projectsApiPath}/${projectId}`,
	workspaceSeasonsApiPath: (workspaceId: string) =>
		`${workspaceApiPath(workspaceId)}${projectEventsRoutes.seasonsApiPath}`,
	seasonApiPath: (seasonId: number) => `${projectEventsRoutes.seasonsApiPath}/${seasonId}`,
	eventApiPath: (eventId: number) => `${projectEventsRoutes.eventsApiPath}/${eventId}`,
	eventArchiveApiPath: (eventId: number) =>
		`${projectEventsRoutes.eventApiPath(eventId)}/archive`,
};
