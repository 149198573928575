import { getHostUrl, isLocalEnvironment } from '@/environment';
import { getAccessTokenFixtureFromCookies } from '@/src/modules/auth/utils/auth.utils';
import { UseMETHODOptions } from '@/src/modules/common/hooks/useGET';
import {
	isCorruptedResponse,
	ResponseError,
} from '@/src/modules/common/utils/errors/ResponseError';

export type UsePATCHOptions = {
	isRawResponse?: boolean;
} & UseMETHODOptions;

export type UsePATCH<TPayload, TData> = (
	payload?: TPayload | null,
	dynamicEndpoint?: string,
) => Promise<TData | null | undefined>;

export const usePATCH = <TPayload, TData>({
	endpoint,
	apiVersion = 2,
	isFullEndpoint,
	isRawResponse,
}: UsePATCHOptions): UsePATCH<TPayload, TData> => {
	return async (payload?: TPayload | null, dynamicEndpoint?: string) => {
		let headers: Record<string, string> = {
			'Content-Type': 'application/json;charset=utf-8',
		};
		let credentials: RequestCredentials | undefined = 'include';

		if (isLocalEnvironment) {
			const accessToken = getAccessTokenFixtureFromCookies();
			headers = { ...headers, authorization: `Bearer ${accessToken}` };
			credentials = undefined;
		}

		const url = endpoint
			? isFullEndpoint
				? typeof endpoint === 'function'
					? endpoint()
					: endpoint
				: typeof endpoint === 'function'
					? `${getHostUrl()}api/v${apiVersion}${endpoint()}`
					: `${getHostUrl()}api/v${apiVersion}${endpoint}`
			: dynamicEndpoint
				? `${getHostUrl()}api/v${apiVersion}${dynamicEndpoint}`
				: '';

		if (isRawResponse) {
			const response = await fetch(url, {
				method: 'PATCH',
				headers,
				credentials,
				body: payload ? JSON.stringify(payload) : null,
			});

			return (await response.text()) as TData;
		}

		const response = await fetch(url, {
			method: 'PATCH',
			headers,
			credentials,
			body: payload ? JSON.stringify(payload) : null,
		});

		const json = await response.json();

		if (isCorruptedResponse(json)) {
			throw new ResponseError(json);
		}

		return json.data;
	};
};
