import {
	ProposalWorkflowStage,
	ProposalWorkflowStatus,
} from '@/src/modules/srm/models/proposal.models';

export const ProposalClosedStatuses: ProposalWorkflowStatus[] = [
	'closed',
	'rejected',
	'duplicated',
	'completed',
	'recalled',
];
export const ProposalHidePublicationStatuses: ProposalWorkflowStatus[] = [
	'closed',
	'rejected',
	'duplicated',
];
export const ProposalHideSitePublicationStatuses: ProposalWorkflowStatus[] = ['recalled'];
export const ProposalAdditionalActionsNotAvailableStatuses: ProposalWorkflowStatus[] = [
	...ProposalClosedStatuses,
	...['analysing'],
] as ProposalWorkflowStatus[];
export const ProposalAdditionalActionsAvailableStages: ProposalWorkflowStage[] = [
	'PRE_PRODUCTION',
	'PRODUCTION',
];
export const ProposalProductionStages: ProposalWorkflowStage[] = ['PRODUCTION', 'POST_PRODUCTION'];
