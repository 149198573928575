'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGET, UseGETReloadFunction } from '@/src/modules/common/hooks/useGET';
import { usePOST } from '@/src/modules/common/hooks/usePOST';
import { ApiMultiResponse } from '@/src/modules/common/models/common.models';
import { workspacesActions } from '@/src/modules/workspaces/actions/workspaces.actions';
import {
	AddWorkspaceRequest,
	WorkspaceEntity,
} from '@/src/modules/workspaces/models/workspaces.models';
import { workspacesApiPath } from '@/src/modules/workspaces/routes/workspaces.api.routes';

export type UseWorkspaces = {
	workspaces?: WorkspaceEntity[];
	isLoading: boolean;
	reload: UseGETReloadFunction;
	getByIdOrSlug: (workspaceId?: string) => WorkspaceEntity | undefined;
	addWorkspace: (data: AddWorkspaceRequest) => Promise<WorkspaceEntity | undefined | null>;
};

export const useWorkspaces = (canGet = true): UseWorkspaces => {
	const [workspaces, setWorkspaces] = useState<WorkspaceEntity[]>();

	const { data, isLoading, reload } = useGET<ApiMultiResponse<WorkspaceEntity>, unknown>({
		endpoint: workspacesApiPath,
		canGet,
	});

	const addWorkspacePost = usePOST<unknown, any>({
		endpoint: () => workspacesApiPath,
	});

	useEffect(() => {
		if (data?.data) {
			setWorkspaces(data.data.map(workspacesActions.addRightsToWorkspace));
		}
	}, [data?.data]);

	const getByIdOrSlug = useCallback(
		(workspaceIdOrSlug?: string) =>
			workspaces?.find(
				(workspace) =>
					workspace.slug === workspaceIdOrSlug || workspace.id === workspaceIdOrSlug,
			),
		[workspaces],
	);

	const addWorkspace = async (data: AddWorkspaceRequest) => {
		const workspaceResponse = await addWorkspacePost(data);

		setWorkspaces((prevState) => [...(prevState ?? []), workspaceResponse]);

		return workspaceResponse;
	};

	return useMemo(
		() => ({
			workspaces,
			isLoading,
			reload,
			getByIdOrSlug,
			addWorkspace,
		}),
		[workspaces, isLoading, reload, getByIdOrSlug, addWorkspace],
	);
};
