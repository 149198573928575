'use client';

import { createRef, CSSProperties, FC, useCallback, useEffect, useState } from 'react';

import {
	routes,
	routesParts,
	urlWithId,
	urlWithIds,
} from '@/src/modules/common/routes/common.routes';
import { Routes } from '@/src/modules/common/routes/common.routes.types';
import { Link } from '@/src/modules/common/utils';
import { useEvent } from '@/src/modules/events/hooks/useEvent';
import { EventEntity } from '@/src/modules/events/models/events.models';
import { Locale, localizeFrom, useAnyTranslations } from '@/src/modules/i18n';
import { createEventMenuItems } from '@/src/modules/srm/utils/events-menu.utils';
import { useWorkspaceFromContext } from '@/src/modules/workspaces/hooks/useWorkspaceFromContext';
import { useLocale, useTranslations } from 'next-intl';
import { useSelectedLayoutSegments } from 'next/navigation';
import { BreadCrumb as BreadCrumbPrimeReact } from 'primereact/breadcrumb';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Skeleton } from 'primereact/skeleton';

export const HAMBURGER_BUTTON_PLACE_ID = 'hamburger-button-place';

export type BreadCrumbProps = {
	showPrivateContent: boolean;
};

const breadCrumbHeightStyles: CSSProperties = { height: '31px' };

export const BreadCrumb: FC<BreadCrumbProps> = ({ showPrivateContent }) => {
	const urlParts = useSelectedLayoutSegments();
	const locale = useLocale();

	const tSidebar = useTranslations('sidebar');
	const tPages = useAnyTranslations('pages');

	const [items, setItems] = useState<MenuItem[]>([]);
	const [pageLoading, setPageLoading] = useState<boolean>(true);

	const { workspace, workspaces, isLoading } = useWorkspaceFromContext(showPrivateContent);

	const canGetEvent = urlParts?.[1] === Routes.WorkspaceEvents && !isNaN(+urlParts?.[2]);

	const { event } = useEvent(workspace?.id, +urlParts?.[2], canGetEvent);

	const homeItem: MenuItem =
		urlParts.length === 0
			? {
					label: tSidebar('items.general'),
					url: routes[Routes.Home].path,
				}
			: {
					label: tSidebar('items.dashboard'),
					url: urlWithId(
						routes[Routes.Dashboard].path,
						routesParts.WorkspaceIdOrSlug,
						workspace?.slug ?? workspace?.id,
					),
				};

	const createEventBreadCrumb = useCallback((event: EventEntity, page: string): MenuItem[] => {
		const newItems: MenuItem[] = [];

		newItems.push({
			label: localizeFrom(event.content?.data?.title, locale),
			// disabled: true,
			template: (item) => (
				<Link
					href={urlWithIds(routes[Routes.Event].path, [
						{
							match: routesParts.WorkspaceIdOrSlug,
							value: workspace?.slug ?? event.workspaceId ?? '',
						},
						{
							match: routesParts.eventId,
							value: event.id?.toString() ?? '',
						},
					])}
					locale={locale as Locale}
					className="p-menuitem-link"
				>
					<span className="p-menuitem-text">{item.label}</span>
				</Link>
			),
		});

		const eventPagesMenuItems = createEventMenuItems({
			tPages,
			locale: locale as Locale,
			event,
			workspaceIdOrSlug: workspace?.slug ?? event?.workspaceId,
			iconClassName: 'mr-2',
		});

		const eventPagesMenuRef = createRef<Menu>();

		newItems.push({
			label: tPages(`event.${page}.title`),
			template: (item) => (
				<>
					<span
						className="p-menuitem-text cursor-pointer"
						onClick={(event) => eventPagesMenuRef.current?.toggle(event)}
					>
						{item.label}
					</span>

					<Menu
						model={eventPagesMenuItems}
						popup
						ref={eventPagesMenuRef}
						id="eventPagesMenu"
						popupAlignment="left"
					/>
				</>
			),
		});

		return newItems;
	}, []);

	const createWorkspaceBreadCrumb = useCallback(() => {
		const newItems: MenuItem[] = [];

		if (workspace && workspaces && workspaces?.length > 0) {
			const workspaceMenuRef = createRef<Menu>();

			newItems.push({
				label: workspace.hint ?? 'Workspace',
				template: (item) => (
					<>
						<span
							className="p-menuitem-text cursor-pointer"
							onClick={(event) => workspaceMenuRef.current?.toggle(event)}
						>
							{item.label}
						</span>
						{workspaces.length > 1 && (
							<Menu
								model={workspaces.map(
									(workspace) =>
										({
											id: workspace.id,
											label: workspace.hint,
											icon: 'pi pi-th-large text-xs',
											disabled: false,
											url: urlWithId(
												routes[Routes.WorkspaceEvents].path,
												routesParts.WorkspaceIdOrSlug,
												workspace.slug ?? workspace.id,
											),
										}) as MenuItem,
								)}
								popup
								ref={workspaceMenuRef}
								id="workspacePagesMenu"
								popupAlignment="left"
							/>
						)}
					</>
				),
			});
		} else {
			newItems.push({
				label: workspace?.hint ?? 'Workspace',
				url: urlWithId(
					routes[Routes.Dashboard].path,
					routesParts.WorkspaceIdOrSlug,
					workspace?.slug ?? workspace?.id,
				),
			});
		}
		return newItems;
	}, [workspace, workspaces]);

	useEffect(() => {
		if (!workspace && !isLoading) {
			setPageLoading(false);
		}

		if (!workspace) {
			return;
		}

		const newItems: MenuItem[] = [];

		if (urlParts?.[0] !== Routes.Dashboard && workspace) {
			newItems.push(...createWorkspaceBreadCrumb());
		}

		if (urlParts?.[1] === Routes.WorkspaceEvents) {
			newItems.push({
				label: tSidebar('items.events'),
				url: urlWithId(
					routes[Routes.WorkspaceEvents].path,
					routesParts.WorkspaceIdOrSlug,
					workspace?.slug ?? workspace?.id,
				),
			});

			if (!isNaN(+urlParts?.[2])) {
				if (!event) {
					setPageLoading(false);

					return;
				}

				const page = urlParts?.[3];

				newItems.push({
					label: localizeFrom(event?.season?.title, locale),
					url: `${urlWithId(
						routes[Routes.WorkspaceEvents].path,
						routesParts.WorkspaceIdOrSlug,
						workspace?.slug ?? workspace?.id,
					)}#season${event?.season?.id}`,
					disabled: true,
				});
				newItems.push(...createEventBreadCrumb(event, page));
			}
		}

		if (urlParts?.[1] === Routes.WorkspaceProjects) {
			newItems.push({
				label: tSidebar('items.projects'),
				url: urlWithId(
					routes[Routes.WorkspaceProjects].path,
					routesParts.WorkspaceIdOrSlug,
					workspace?.slug ?? workspace?.id,
				),
			});

			if (urlParts.length === 4) {
				newItems.push({
					label: tSidebar('items.project-edit'),
					disabled: true,
				});
			}
		}

		if (urlParts?.[1] === Routes.WorkspaceVenues) {
			newItems.push({
				label: tSidebar('items.venues'),
				url: urlWithId(
					routes[Routes.WorkspaceVenues].path,
					routesParts.WorkspaceIdOrSlug,
					workspace?.slug ?? workspace?.id,
				),
			});

			if (urlParts.length === 4) {
				newItems.push({
					label: tSidebar('items.venue-edit'),
					disabled: true,
				});
			}
		}

		if (urlParts?.[3] === Routes.Team) {
			newItems.push({
				label: tSidebar('items.team'),
				url: '',
				// url: urlWithId(
				// 	routes[Routes.WorkspaceVenues].path,
				// 	routesParts.workspace,
				// 	workspace?.slug ?? workspace?.id,
				// ),
			});

			if (urlParts.length === 5) {
				newItems.push({
					label: tSidebar('items.team-edit'),
					disabled: true,
				});
			}
		}

		setItems(newItems);

		setPageLoading(false);
	}, [
		isLoading,
		workspace,
		event,
		urlParts?.[0],
		urlParts?.[1],
		urlParts?.[2],
		urlParts?.[3],
		locale,
	]);

	if (!showPrivateContent || !urlParts?.[1]) {
		return (
			<div style={breadCrumbHeightStyles} className="flex gap-2">
				<div id={HAMBURGER_BUTTON_PLACE_ID} style={{ paddingTop: '6px' }} />
			</div>
		);
	}

	if (pageLoading || items.length === 0) {
		return (
			<div className="flex" style={breadCrumbHeightStyles}>
				<Skeleton height="16px" width="75px"></Skeleton>
				<i className="pi pi-angle-right opacity-40" />
				<Skeleton height="16px" width="75px"></Skeleton>
				<i className="pi pi-angle-right opacity-40" />
				<Skeleton height="16px" width="75px"></Skeleton>
				<i className="pi pi-angle-right opacity-40" />
				<Skeleton height="16px" width="75px"></Skeleton>
				<i className="pi pi-angle-right opacity-40" />
				<Skeleton height="16px" width="75px"></Skeleton>
			</div>
		);
	}

	if (workspaces && workspaces.length === 0) {
		return <div className="flex" style={breadCrumbHeightStyles} />;
	}

	return (
		<div style={breadCrumbHeightStyles} className="flex gap-2">
			<div id={HAMBURGER_BUTTON_PLACE_ID} />

			<BreadCrumbPrimeReact
				model={items}
				home={homeItem}
				className="bg-transparent border-none px-0 pt-0 hidden sm:block flex-wrap"
				separatorIcon={
					<span
						className="pi pi-angle-right opacity-40"
						style={{ marginLeft: '-4px', marginRight: '-4px' }}
					/>
				}
			/>
		</div>
	);
};
