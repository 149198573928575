import { Route, RouteParts, Routes } from '@/src/modules/common/routes/common.routes.types';

export const routesParts: Record<RouteParts, string> = {
	[RouteParts.WorkspaceIdOrSlug]: '[workspaceId]',
	[RouteParts.EventId]: '[eventId]',
	[RouteParts.UserId]: '[userId]',
	[RouteParts.ProjectId]: '[projectId]',
	[RouteParts.VenueId]: '[venueId]',
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const routes: Record<Routes, Route> = {
	[Routes.Home]: {
		path: `/`,
		private: false,
	},
	[Routes.WorkspaceHome]: {
		path: `/${routesParts.WorkspaceIdOrSlug}`,
		private: true,
	},
	[Routes.Dashboard]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/dashboard`,
		private: true,
	},
	[Routes.Settings]: {
		path: '/settings',
		private: true,
	},
	[Routes.Support]: {
		path: '/support',
		private: false,
	},
	[Routes.Company]: {
		path: '/company',
		private: true,
	},
	[Routes.CreateWorkspace]: {
		path: '/workspace/create',
		private: true,
	},
	[Routes.EditWorkspace]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/edit`,
		private: true,
	},
	[Routes.WorkspaceUsers]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/users`,
		private: true,
	},
	[Routes.WorkspaceProjects]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/projects`,
		private: true,
	},
	[Routes.WorkspaceProjectTeam]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/projects/${routesParts.projectId}/team`,
		private: true,
	},
	[Routes.WorkspaceEditProject]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/projects/${routesParts.projectId}/edit`,
		private: true,
	},
	[Routes.WorkspaceVenues]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/venues`,
		private: true,
	},
	[Routes.WorkspaceEditVenue]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/venues/${routesParts.VenueId}/edit`,
		private: true,
	},
	[Routes.WorkspaceUserDetails]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/users/details/${routesParts.userId}`,
		private: true,
	},
	[Routes.WorkspaceEvents]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events`,
		private: true,
	},
	[Routes.CreateEvent]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/create`,
		private: true,
	},
	[Routes.Event]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}`,
		private: true,
	},
	[Routes.EventStatus]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/status`,
		private: true,
	},
	[Routes.EventReport]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/report`,
		private: true,
	},
	[Routes.EventConfig]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/config`,
		private: true,
	},
	[Routes.EventTeam]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/team`,
		private: true,
	},
	[Routes.EventIntegrations]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/integrations`,
		private: true,
	},
	[Routes.EventSchedule]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/schedule`,
		private: true,
	},
	[Routes.EventDesign]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/design`,
		private: true,
	},
	[Routes.EventUsers]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/users`,
		private: true,
	},
	[Routes.EventUserDetails]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/users/details/${routesParts.userId}`,
		private: true,
	},
	[Routes.EventActivities]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/activities`,
		private: true,
	},
	[Routes.EventPublications]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/publications`,
		private: true,
	},
	[Routes.EventRatings]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/ratings`,
		private: true,
	},
	[Routes.EventSupportCalendar]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/support-calendar`,
		private: true,
	},
	[Routes.EventScheduleLive]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/events/${routesParts.eventId}/schedule-live`,
		private: true,
	},
	[Routes.Team]: {
		path: `/${routesParts.WorkspaceIdOrSlug}/team`,
		private: true,
	},
};

// export const matchLinkWithRouteWithId = (
// 	links: string[] | null,
// 	route: Routes,
// 	id: number,
// ): boolean => (links === null ? false : `${links[0]}\\${links[1]}` === `${route}\\${id}`);

// export const matchLastLinksPart = (route: Routes, part: string | undefined): boolean =>
// 	part === undefined ? false : route.endsWith(part);

export const urlWithId = (path: string, match: string, id: number | string | undefined) =>
	path.replace(match, id?.toString() ?? 'undefined');

export const urlWithIds = (
	path: string,
	matches: {
		match: string;
		value?: number | string;
	}[],
) =>
	matches.reduce(
		(previousValue, currentValue) =>
			urlWithId(previousValue, currentValue.match, currentValue.value),
		path,
	);
