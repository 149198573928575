import { commonActions } from '@/src/modules/common/actions/common.actions';
import { rightsActions } from '@/src/modules/common/actions/rights.actions';
import {
	COMMON_FILTER_SCOPE_KEY_FILTER,
	COMMON_FILTER_TEXT_KEY_FILTER,
	COMMON_FILTER_TYPE_KEY_FILTER,
} from '@/src/modules/common/consts/filters.const';
import { FilterPanelData } from '@/src/modules/common/hooks/useFilterData';
import {
	EntityActions,
	KeyValueCount,
	LocaleMessageTypeArray,
} from '@/src/modules/common/models/common.models';
import { groupBy, removeUndefined } from '@/src/modules/common/utils';
import { TalkAnalyticsAggregate } from '@/src/modules/mdm/models/analytics.models';
import {
	ScheduleDayEntity,
	ScheduleSlotEntity,
	ScheduleTrackEntity,
} from '@/src/modules/schedule/models/schedule.models';
import { proposalActions } from '@/src/modules/srm/actions/proposal.actions';
import {
	EVENT_REPORT_FILTER_FORMAT_KEY,
	EVENT_REPORT_FILTER_FORMAT_KEY_TYPE,
} from '@/src/modules/srm/components/EventReport/eventReport.utils';
import {
	ActivityEntityScopeValuePrefix,
	ActivityEntityTypeValuePrefix,
} from '@/src/modules/srm/consts/const';
import {
	ActivityContentData,
	ActivityEntity,
	ActivityEntityView,
	PublicationAggregate,
	PublicationAggregateView,
} from '@/src/modules/srm/models/activity.models';
import { ProposalEntity } from '@/src/modules/srm/models/proposal.models';
import { AssetEntity, ContentStatus } from '@/src/modules/srm/models/squidex.models';
import {
	ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_FILTER,
	filterProposalsByText,
} from '@/src/modules/srm/utils/activities.utils';

export type ActivitiesControllerMode = 'activities' | 'publications';

export const activityActions = {
	addRightsToActivity: (data?: EntityActions & ActivityEntity): ActivityEntity => ({
		...(data ?? {}),
		rights: {
			...commonActions.entityActions.getRightsFromEntity(data),
			canUploadPresentation: rightsActions.can(data, 'upload-presentation'),
			canListMembers: rightsActions.canListNestedResource(data, 'member'),
			canAddMember: rightsActions.canNestedResource(data, 'member', 'register'),
		},
	}),
	isSpeakerRoomIdNeed(activityContentData?: ActivityContentData): boolean {
		if (!activityContentData) {
			return false;
		}
		const data = activityContentData?.internalSlots?.iv;

		if (data?.broadcast === 'standard' || !data?.broadcast) {
			return true;
		}

		// noinspection RedundantIfStatementJS
		if (
			data?.broadcast === 'disabled' &&
			(data?.discussion === 'disabled' || data?.discussion === 'external')
		) {
			return false;
		}

		return true;
	},
	extractOGImagesUrls: (
		activity?: ActivityEntity,
		deleteDefaultOg = false,
	): LocaleMessageTypeArray => {
		if (!activity) {
			return { ru: [], en: [] };
		}

		const ogIds = activity?.content?.data?.OGImage;

		if (!ogIds) {
			return { ru: [], en: [] };
		}

		const assets = activity?.content?.assets;

		if (
			deleteDefaultOg &&
			ogIds?.ru &&
			ogIds?.en &&
			ogIds.ru.length > 0 &&
			ogIds.en.length > 0
		) {
			if (ogIds.ru[0] === ogIds.en[0]) {
				return {
					ru: (assets?.filter((a) => a.id && ogIds.ru?.includes(a.id)) ?? []).map(
						(a) => a.links?.content ?? '',
					),
					en: [],
				};
			}
		}

		return {
			ru: (assets?.filter((a) => a.id && ogIds.ru?.includes(a.id)) ?? []).map(
				(a) => a.links?.content ?? '',
			),
			en: (assets?.filter((a) => a.id && ogIds.en?.includes(a.id)) ?? []).map(
				(a) => a.links?.content ?? '',
			),
		};
	},
	aggregateTransform: (
		mode: ActivitiesControllerMode,
		proposals?: PublicationAggregate[] | null,
		isEditor = true,
		isCoordinator = true,
		isGlobalAdmin = true,
	): PublicationAggregateView[] | null | undefined => {
		if (proposals === null) {
			return null;
		}
		if (proposals === undefined) {
			return undefined;
		}
		const result: PublicationAggregateView[] = [];

		const activityTransform = (activity: ActivityEntity): ActivityEntityView => {
			const ogImages = activityActions.extractOGImagesUrls(activity, true);
			return {
				...activity,
				ogImages,
				ogPresent:
					(ogImages?.ru ? ogImages?.ru?.length > 0 : false) &&
					(ogImages?.en ? ogImages?.en?.length > 0 : false),
			};
		};

		proposals.forEach((proposal) => {
			const contentStatuses: ContentStatus[] =
				proposal?.members
					?.filter((p) => p?.eventProfile?.biography?.content?.status)
					?.map((p) => p.eventProfile!.biography!.content!.status!) ?? [];
			const importantNotePresent =
				contentStatuses &&
				!commonActions.contentActions.allIsExactlyPublished(contentStatuses);
			const activity = proposal.activity ? activityTransform(proposal.activity) : undefined;
			const activityImportantTooltips = activityActions.getActivityImportantTooltips(
				activity,
				importantNotePresent,
				proposal,
				isEditor,
				isCoordinator,
				isGlobalAdmin,
			);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const data: PublicationAggregateView[] =
				proposal.members
					?.filter((member) => !!member)
					.map((member, index) => ({
						...proposal,
						activity,
						activityImportantTooltips,
						participationsImportantNotePresent: importantNotePresent,
						member,
						index,
					})) ?? [];

			result.push(...data);
		});

		return mode === 'activities'
			? result
			: mode === 'publications'
				? result.sort(activityActions.sortActivitiesAggregatesByStatus)
				: result.sort(activityActions.sortActivitiesAggregatesByStatus);
	},
	sortActivitiesAggregatesByProposal: (a: PublicationAggregate, b: PublicationAggregate) => {
		return (b?.id ?? 0) - (a?.id ?? 0);
	},
	sortActivitiesAggregatesByStatus: (
		a: PublicationAggregateView,
		b: PublicationAggregateView,
	) => {
		if (a.activityImportantTooltips.length > b.activityImportantTooltips.length) {
			return -1;
		}

		return 1;
	},
	getActivityImportantTooltips: (
		activity: ActivityEntityView | undefined,
		participationsImportantNotePresent: boolean,
		proposal: ProposalEntity | undefined,
		isEditor: boolean,
		isCoordinator: boolean,
		isGlobalAdmin: boolean,
	): string[] => {
		const status = activity?.content?.status;
		if (!status) {
			return [];
		}
		const ogPresent = activity?.ogPresent;
		const presentationFilesExist =
			activityActions.extractPresentationFiles(activity)?.length > 0;
		const edited = commonActions.contentActions.isEdited(status);
		const draft = commonActions.contentActions.isDraft(status);
		const productionStage = proposalActions.isProposalProductionStage(proposal);

		const tooltips: string[] = [];

		if (!productionStage) {
			tooltips.push('informationStageImportant');
		}

		if (edited) {
			tooltips.push('contentStatusEditedTooltip');
		}

		if (draft) {
			tooltips.push('contentStatusDraftTooltip');
		}

		if (participationsImportantNotePresent) {
			tooltips.push('informationParticipantImportant');
		}

		if ((isEditor || isGlobalAdmin) && !ogPresent) {
			tooltips.push('openGraphTooltipOff');
		}

		if ((isCoordinator || isGlobalAdmin) && !presentationFilesExist) {
			tooltips.push('informationPresentationImportant');
		}

		return tooltips.filter((t) => t);
	},
	extractPresentationFiles: (activity?: ActivityEntity): AssetEntity[] => {
		const ids = activity?.content?.data?.presentation?.iv?.files;

		if (!ids) {
			return [];
		}

		if (ids.length === 0) {
			return [];
		}

		return (
			activity?.content?.assets
				?.filter((a) => a.id && ids.includes(a.id))
				.sort((a, b) =>
					a.created && b.created && new Date(a.created) > new Date(b.created) ? -1 : 1,
				) ?? []
		);
	},
	isPartnerScope: (activity?: ActivityEntity): boolean | undefined =>
		activity?.scope === undefined ? undefined : activity.scope === 'PARTNER',
	isMeetupScope: (activity?: ActivityEntity): boolean | undefined =>
		activity?.scope === undefined ? undefined : activity.scope === 'MEETUP',
	isRegularScope: (activity?: ActivityEntity): boolean | undefined =>
		activity?.scope === undefined ? undefined : activity.scope === 'REGULAR',
	filterProposalsByTextFn(p: PublicationAggregate, t: string): boolean | undefined {
		return (
			p.activity?.content?.data?.title?.ru?.toLowerCase().includes(t) ||
			p.activity?.content?.data?.title?.en?.toLowerCase().includes(t) ||
			p.members?.some(
				(p) =>
					p.eventProfile?.biography?.content?.data?.firstName?.ru
						?.toLowerCase()
						.includes(t) ||
					p.eventProfile?.biography?.content?.data?.firstName?.en
						?.toLowerCase()
						.includes(t),
			) ||
			p.members?.some(
				(p) =>
					p.eventProfile?.biography?.content?.data?.lastName?.ru
						?.toLowerCase()
						.includes(t) ||
					p.eventProfile?.biography?.content?.data?.lastName?.en
						?.toLowerCase()
						.includes(t),
			)
		);
	},
	getActivitiesFormatValuesDistinctWithCount: (
		aggregatesForCount: TalkAnalyticsAggregate[],
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		localeAs,
	): KeyValueCount<string>[] => {
		const keys: EVENT_REPORT_FILTER_FORMAT_KEY_TYPE[] = ['offline', 'online'];
		const groupingForCount = groupBy(aggregatesForCount, (a) =>
			a?.talksStatistic?.offline_unique_users ? 'offline' : 'online',
		);

		return keys.map((key) => ({
			label: localeAs(`${EVENT_REPORT_FILTER_FORMAT_KEY}.${key}`),
			key,
			count: groupingForCount?.get(key as 'offline' | 'online')?.length ?? 0,
		}));
	},
	getActivitiesTypeValuesDistinctWithCount: (
		activities: ActivityEntity[],
		activitiesForCount: ActivityEntity[],
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		localeAs,
	): KeyValueCount<string>[] => {
		const groupingForTypes = groupBy(activities, (a) => a.typeValue);
		const groupingForCount = groupBy(activitiesForCount, (a) => a.typeValue);

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return Array.from(groupingForTypes ?? []).map(([key, _]) => ({
			label: localeAs(`${ActivityEntityTypeValuePrefix}.${key}`),
			key,
			count: groupingForCount?.get(key)?.length ?? 0,
		})) as KeyValueCount<string>[];
	},
	getActivitiesScopesDistinctWithCount: (
		activities: ActivityEntity[],
		activitiesForCount: ActivityEntity[],
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		localeAs,
	): KeyValueCount<string>[] => {
		const groupingForTypes = groupBy(activities, (a) => a.scope);
		const groupingForCount = groupBy(activitiesForCount, (a) => a.scope);

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return Array.from(groupingForTypes ?? []).map(([key, _]) => ({
			label: localeAs(`${ActivityEntityScopeValuePrefix}.${key}`),
			key,
			count: groupingForCount?.get(key)?.length ?? 0,
		})) as KeyValueCount<string>[];
	},
	filterActivitiesForPublications(
		proposals: PublicationAggregate[] | null | undefined,
		filtersData: FilterPanelData | undefined,
	): PublicationAggregate[] | null | undefined {
		if (!proposals || !filtersData) {
			return proposals;
		}
		let filteredActivities = proposals;
		const filterByTypes = filtersData.filters.find(COMMON_FILTER_TYPE_KEY_FILTER)?.data;
		if (filterByTypes?.length > 0) {
			filteredActivities = filteredActivities.filter((a) =>
				filterByTypes.includes(a.activity?.typeValue),
			);
		}

		const filterByScopes = filtersData.filters.find(COMMON_FILTER_SCOPE_KEY_FILTER)?.data;
		if (filterByScopes?.length > 0) {
			filteredActivities = filteredActivities.filter((a) =>
				filterByScopes.includes(a.activity?.scope),
			);
		}

		const filterByReservedStage = filtersData.filters.find(
			ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_FILTER,
		)?.data;
		if (filterByReservedStage) {
			filteredActivities = filteredActivities.filter(
				(a) => a?.workflow?.status === 'reserved',
			);
		}

		const filterByText = filtersData.filters.find(COMMON_FILTER_TEXT_KEY_FILTER)?.data;

		return filterProposalsByText(filteredActivities, filterByText);
	},
	sortActivitiesBySlotPresent: (
		a: PublicationAggregate,
		b: PublicationAggregate,
		currentSlots: {
			slot: ScheduleSlotEntity;
			day: ScheduleDayEntity;
			track: ScheduleTrackEntity;
		}[],
	) => {
		const isPresentSlotsA = currentSlots.some((s) => s.slot?.activityId === a.activity?.id);
		const isPresentSlotsB = currentSlots.some((s) => s.slot?.activityId === b.activity?.id);

		if (isPresentSlotsB && !isPresentSlotsA) {
			return 1;
		} else {
			return -1;
		}
	},
	editAction: {
		// saveStringAction: (id: number, data: string, fieldName: string, srmService: SrmService) =>
		// 	srmService.saveActivityData(id, { [fieldName]: data }),
		// saveMultipleStringAction: (id: number, data: { data: string; fieldName: string }[], srmService: SrmService) => {
		// 	const sendData = {};
		// 	data.forEach((d) => {
		// 		sendData[d.fieldName] = d.data;
		// 	});
		// 	return srmService.saveActivityData(id, sendData);
		// },
		// saveLocalizeStringAction: (id: number, data: LocalizeString, fieldName: string, srmService: SrmService) =>
		// 	srmService.saveActivityData(id, { [fieldName]: data }),
		// saveLocalizeStringContentDataAction: (
		// 	id: number,
		// 	data: LocalizeString,
		// 	fieldName: string,
		// 	srmService: SrmService,
		// ) =>
		// 	srmService.saveActivityData(
		// 		id,
		// 		{
		// 			content: {
		// 				data: {
		// 					[fieldName]: data,
		// 				},
		// 			},
		// 		},
		// 		true,
		// 	),
		// saveStringContentDataAction: (id: number, data: string, fieldName: string, srmService: SrmService) =>
		// 	srmService.saveActivityData(
		// 		id,
		// 		{
		// 			content: {
		// 				data: {
		// 					[fieldName]: data,
		// 				},
		// 			},
		// 		},
		// 		true,
		// 	),
		removeContentDataNotEditableFields: (data: ActivityContentData): object =>
			removeUndefined({
				...data,
				id: undefined,
				OGImage: undefined,
				committeeComment: undefined,
				eventId: undefined,
				eventProject: undefined,
				eventVersion: undefined,
				fullDescription: undefined,
				links: undefined,
				marketingComment: undefined,
				options: undefined,
				presentation: undefined,
				shortDescription: undefined,
				title: undefined,
			}),
	},
};
