import { getSquidexUrl } from '@/environment';
import {
	COMMON_FILTER_SCOPE_KEY_DEFAULT,
	COMMON_FILTER_TYPE_KEY_DEFAULT,
} from '@/src/modules/common/consts/filters.const';
import {
	FILTER_LOGIC_VERSION,
	FilterData,
	FilterPanelData,
	FilterPanelInitData,
	SetFilterDispatchEffector,
	SetFilterDispatchObject,
} from '@/src/modules/common/hooks/useFilterData';
import { activityActions } from '@/src/modules/srm/actions/activity.actions';
import { PublicationAggregate } from '@/src/modules/srm/models/activity.models';

export const createSquidexUrlToBiography = (biographyId?: string | number): string =>
	`${getSquidexUrl()}/app/srm/content/srm-biography/${biographyId}/history`;

export const createSquidexUrlToActivity = (activityId: string | number | undefined): string =>
	`${getSquidexUrl()}/app/srm/content/srm-activity/${activityId}/history`;

export const filterProposalsByText = (
	proposals: PublicationAggregate[],
	text?: string,
): PublicationAggregate[] => {
	if (!text) {
		return proposals;
	}

	const t = text.toLowerCase();
	return proposals.filter((a) => activityActions.filterProposalsByTextFn(a, t));
};

export const ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY = 'page_publications_filter_stage';
export const ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_NEGATIVE =
	'page_publications_filter_stage_negative';
export const ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY = 'page_publications_filter_reserved';

export const ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_DEFAULT: FilterData = {
	key: ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY,
	label: ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY,
	negativeLabel: ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_NEGATIVE,
	data: true,
	type: 'boolean',
	order: 0,
};

export const ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_DEFAULT: FilterData = {
	key: ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY,
	label: ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY,
	negativeLabel: ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY,
	data: false,
	type: 'boolean',
	order: 1,
};

export const ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_FILTER = (f: FilterData) =>
	f.key === ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY;
export const ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_FILTER = (f: FilterData) =>
	f.key === ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY;

export const ActivitiesFiltersDefaults: FilterPanelInitData = {
	filters: [
		ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_DEFAULT,
		ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_DEFAULT,
		COMMON_FILTER_TYPE_KEY_DEFAULT,
		COMMON_FILTER_SCOPE_KEY_DEFAULT,
	],
	version: FILTER_LOGIC_VERSION,
};

export const ActivitiesForRatingsFiltersDefaults: FilterPanelInitData = {
	filters: [COMMON_FILTER_TYPE_KEY_DEFAULT, COMMON_FILTER_SCOPE_KEY_DEFAULT],
	version: FILTER_LOGIC_VERSION,
};

export const filterComplementEffect: SetFilterDispatchEffector = (
	filtersData: FilterPanelData | undefined,
	newFiltersData: SetFilterDispatchObject[],
): SetFilterDispatchObject[] => {
	const filterByReservedStage = filtersData?.filters.find(
		ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_FILTER,
	)?.data;
	const filterByProductionStage = filtersData?.filters.find(
		ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_FILTER,
	)?.data;

	const result: SetFilterDispatchObject[] = [];

	for (const newFilterData of newFiltersData) {
		if (
			newFilterData.key === ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY &&
			(newFilterData.value || newFilterData.value === undefined) &&
			filterByReservedStage
		) {
			result.push({ key: ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY, value: false });
		} else if (
			newFilterData.key === ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY &&
			newFilterData.value &&
			filterByProductionStage
		) {
			result.push({ key: ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY, value: false });
		}
	}

	return result;
};
