import { rightsActions } from '@/src/modules/common/actions/rights.actions';
import {
	EntityNumber,
	EntityRightsTypes,
	EntityString,
	LocaleMessageType,
} from '@/src/modules/common/models/common.models';
import {
	NameColorEntity,
	ScheduleVersionEntity,
} from '@/src/modules/schedule/models/schedule.models';
import { memberActions } from '@/src/modules/srm/actions/member.actions';
import { BiographyEntity } from '@/src/modules/srm/models/member.models';
import {
	AssetEntity,
	AssetId,
	Content,
	ContentStatus,
	ObjectStatus,
} from '@/src/modules/srm/models/squidex.models';

import { deepEqual } from '../utils/common';

export const commonActions = {
	localizeStringActions: {
		isSomeNull: (l?: LocaleMessageType) => (l !== undefined ? !l.en || !l.ru : false),
	},
	contentActions: {
		isPublished: (c?: ContentStatus) => (c !== undefined ? c === 'PUBLISHED' : false),
		isEdited: (c?: ContentStatus) => (c !== undefined ? c === 'EDITED' : false),
		allIsExactlyPublished: (cs: ContentStatus[]) => cs.every((c) => c === 'PUBLISHED'),
		isDraft: (c?: ContentStatus) => (c !== undefined ? c === 'DRAFT' : false),
		oneIsDraft: (cs: ContentStatus[]) => cs.some((c) => c === 'DRAFT'),
		isArchive: (c?: ContentStatus) => (c !== undefined ? c === 'ARCHIVED' : false),
		oneIsArchive: (cs: ContentStatus[]) => cs.some((c) => c === 'ARCHIVED'),
	},
	entityActions: {
		getRightsFromEntity: (data?: any): Record<EntityRightsTypes, boolean> => ({
			canRead: rightsActions.canPatch(data),
			canDelete: rightsActions.canDelete(data),
			canPatch: rightsActions.canPatch(data),
		}),
		isActive: (c?: ObjectStatus) => (c !== undefined ? c === 'active' : false),
		sortById: (a: EntityNumber | EntityString, b: EntityNumber | EntityString) => {
			if (!a?.id || !b?.id) {
				return 0;
			}

			if (typeof a.id === 'number' && typeof b.id === 'number')
				return (a.id ?? 0) - (b.id ?? 0);
			else if (typeof a.id === 'string' && typeof b.id === 'string')
				return ('' + a.id).localeCompare(b.id);

			return 0;
		},
	},
	imageActions: {
		getScaledImageUrl: (
			image?: string,
			width?: number,
			height?: number,
			mode?: string,
			cache?: number,
		) => {
			if (!image) {
				return undefined;
			}

			const url = new URL(image);
			if (width) {
				url.searchParams.append('width', width.toString());
			}
			if (height) {
				url.searchParams.append('height', height.toString());
			}
			if (mode) {
				url.searchParams.append('mode', mode);
			}
			url.searchParams.append('cache', cache?.toString() ?? '3600');

			return url.toString();
		},
		extractImagesUrlsFromInvariant: <T>(
			content?: Content<T>,
			field?: string,
		): AssetEntity[] => {
			if (!content?.data || !field) {
				return [];
			}

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const ids = content.data[field] as AssetId;
			const assets = content?.assets;

			if (!ids?.iv || !assets || ids?.iv?.length === 0 || assets?.length === 0) {
				return [];
			}

			return (
				content.assets
					?.filter((a) => a.id && ids.iv!.includes(a.id))
					.sort((a, b) =>
						a.created && b.created && new Date(a.created) > new Date(b.created)
							? -1
							: 1,
					) ?? []
			);
		},
	},
	twitterActions: {
		makeTwitterLink: (biography?: BiographyEntity) => {
			if (!biography) {
				return undefined;
			}
			const twitter = memberActions.extractTwitterData(biography);

			if (!twitter) {
				return undefined;
			}
			return `https://twitter.com/${twitter}`;
		},
		getFieldValueWithoutSymbols: (v: string, symbols: string | RegExp) =>
			v?.replaceAll(symbols, ''),
	},
	scheduleActions: {
		comparatorByDate: (a: ScheduleVersionEntity, b: ScheduleVersionEntity) =>
			b?.modifiedAt && a?.modifiedAt
				? b?.modifiedAt > a?.modifiedAt
					? 1
					: -1
				: // eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					b?.createdAt > a?.createdAt
					? 1
					: -1,
		compareNameColorEntity: (a: NameColorEntity[], b: NameColorEntity[]) => {
			if (a.length !== b.length) {
				return true;
			}

			let result = false;

			a.forEach((s, i) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (!deepEqual(s, b[i])) {
					result = true;
				}
			});

			return result;
		},
		getEditNameColorEntities: (a: NameColorEntity[], b: NameColorEntity[]) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const result = [];

			a.forEach((s, i) => {
				if (b[i] !== undefined) {
					if (s.id !== b[i].id || s.name !== b[i].name || s.color !== b[i].color) {
						result.push(b[i]);
					}
				}
			});

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return result;
		},
	},
};
