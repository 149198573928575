import { Form } from '@/src/modules/common/models/common.models';

export const DATA_KEY = 'jugru.org.srm';

export const ActivityEntityTypeValuePrefix = 'activity.type';
export const ActivityEntityScopeValuePrefix = 'activity.scope';

export const SAVED_EVENTLIST_DATA_KEY = `${DATA_KEY}.eventlist_opened`;

export type PageNameDataKey = 'activities' | 'publications' | 'report' | 'ratings-activities';

export const SAVED_FILTERS_DATA_KEY = (eventId: number, mode: PageNameDataKey) =>
	`${DATA_KEY}.${mode}_${eventId}_filter`;

export const SAVED_HIDED_COLUMNS_DATA_KEY = (mode: PageNameDataKey) =>
	`${DATA_KEY}.${mode}_hided_columns`;

export const ProposalCopyFormDefault: Form[] = (
	[
		{
			type: 'content_publishing',
			accepted: undefined,
			order: 0,
		},
		{
			type: 'welcome_notification',
			accepted: true,
			acceptedByThirdParty: true,
			inverted: true,
			order: 1,
		},
		{
			type: 'working_out_notification',
			accepted: true,
			acceptedByThirdParty: true,
			inverted: true,
			order: 2,
		},
		{
			type: 'proposal_status_notification',
			accepted: true,
			acceptedByThirdParty: true,
			inverted: true,
			order: 3,
		},
	] as Form[]
)
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	.sort((a, b) => (a.order > b.order ? 1 : -1));
