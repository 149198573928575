// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const onlyUnique = (value: any, index: number, self: any): boolean =>
	self.indexOf(value) === index;

export const findInString = (source: string, desc: string): boolean => {
	return source.toLowerCase().indexOf(desc) > -1;
};

export const convertArrayToObject = (array: any[], key: string) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
};

export const removeUndefined = (obj) => {
	if (Array.isArray(obj)) {
		return obj.filter((a) => a);
	}

	for (const k in obj) {
		if (obj[k] === undefined) {
			delete obj[k];
		}
	}
	return obj;
};

export function debounce(func, wait) {
	let timeout;
	return function (...args) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const context = this;
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			timeout = null;
			func.apply(context, args);
		}, wait);
	};
}

export function groupBy<K, V>(
	array: V[] | undefined,
	grouper: (item: V) => K | undefined,
): Map<K, V[]> | undefined {
	if (!array) {
		return undefined;
	}
	return array.reduce((store, item) => {
		const key = grouper(item);
		if (!key) {
			return store;
		}
		if (!store.has(key)) {
			store.set(key, [item]);
		} else {
			store.get(key).push(item);
		}
		return store;
	}, new Map<K, V[]>());
}

export function groupByWithExtractor<K, V, T>(
	array: V[],
	grouper: (item: V) => K,
	extractor: (item: V) => T,
) {
	if (!array) {
		return undefined;
	}
	return array.reduce((store, item) => {
		const key: K = grouper(item);
		if (!store.has(key)) {
			store.set(key, [extractor(item)]);
		} else {
			store.get(key).push(extractor(item));
		}
		return store;
	}, new Map<K, T[]>());
}

export function groupBySingle<K, V>(array: V[], grouper: (item: V) => K) {
	return array.reduce((store, item) => {
		const key = grouper(item);
		if (!store.has(key)) {
			store.set(key, item);
		}
		return store;
	}, new Map<K, V>());
}

export function groupBySingleWithExtractor<K, V, T>(
	array: V[],
	grouper: (item: V) => K,
	extractor: (item: V) => T,
) {
	return array.reduce((store, item) => {
		const key = grouper(item);
		if (!store.has(key)) {
			store.set(key, extractor(item));
		}
		return store;
	}, new Map<K, T>());
}

export const hexToRgb = (hex) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
				// eslint-disable-next-line no-mixed-spaces-and-tabs
			}
		: null;
};

export const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

export const numberWithSpaces = (nr) => nr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const arrayWeakEquals = (a, b) =>
	Array.isArray(a) &&
	Array.isArray(b) &&
	a.length === b.length &&
	a.every((val) => b.some((v) => v === val));

export const deepEqual = function (x, y) {
	if (x === y) {
		return true;
	} else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
		if (Object.keys(x).length != Object.keys(y).length) {
			return false;
		}

		for (const prop in x) {
			// eslint-disable-next-line no-prototype-builtins
			if (y.hasOwnProperty(prop)) {
				if (!deepEqual(x[prop], y[prop])) {
					return false;
				}
			} else {
				return false;
			}
		}

		return true;
	} else {
		return false;
	}
};

export function flattenObject(obj: object, keysToRemove: string[]) {
	for (const key in obj) {
		// Check that we are running on the object key
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(key)) {
			// Check to see if the current key is in the "black" list or not
			if (keysToRemove.indexOf(key) > -1) {
				// Process the inner object without this key
				delete obj[key];
			} else {
				if (typeof obj[key] === 'object') flattenObject(obj[key], keysToRemove);
			}
		}
	}

	return obj;
}

export const isNumeric = (str: any) => {
	if (typeof str !== 'string') {
		return false;
	} // we only process strings!

	return (
		!isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	); // ...and ensure strings of whitespace fail
};

export type KeyValuePair<T, K> = {
	key: T;
	value: K;
};

export const groupByField = <K, T>(fieldName: string, data: T[]): Map<K, T[]> => {
	const map = new Map<K, T[]>();

	data.forEach((item) => {
		if (map.has(item[fieldName])) {
			map.set(item[fieldName], [...map.get(item[fieldName]), item]);
		} else {
			map.set(item[fieldName], [item]);
		}
	});

	return map;
};

export const countByField = <T, K>(
	fieldName: string,
	data: T[],
	groupFn: (value: K) => any,
): KeyValuePair<string, number>[] => {
	// reduce array to crop data
	const cropData = data.map((d: any) => {
		return {
			group: groupFn(d[fieldName]) as string,
		};
	});

	return cropData.reduce((acc: KeyValuePair<string, number>[], current: any) => {
		let entity = acc.find((e) => e.key === current.group);
		if (entity === undefined) {
			entity = {
				key: current.group,
				value: cropData.filter((c) => c.group === current.group).length,
			};
			acc.push(entity);
		}
		return acc;
	}, []);
};

export const firstAndLast = <T>(array: T[]): T[] => {
	if (array.length > 1) {
		return [array[0], array[array.length - 1]];
	}
	return [array[0]];
};

export const compareStringInLowercase = (
	string1: string | undefined,
	string2: string | undefined,
): boolean => {
	if (!string1 || !string2) {
		return false;
	}

	return string1.toLowerCase().includes(string2.toLowerCase());
};

export const defaultName: Record<string, string> = {
	ru: 'Название не определено',
	en: 'Name no defined',
};
